/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { createGenerateClassName } from '@mui/styles';

// from https://www.joshwcomeau.com/gatsby/the-worlds-sneakiest-route-change/#preserving-scroll-position-4
export const shouldUpdateScroll = ({ routerProps }) => {
    if(routerProps.location.state) {
        const { disableScrollUpdate } = routerProps.location.state
        return !disableScrollUpdate
    } else {
        return true
    }
  }

// adjust the order of injected jss styles
// A basic theme for this app
const theme = createTheme({
    palette: {
        background: {
            default: '#fff',
        },
    },
});

const generateClassName = createGenerateClassName({
    productionPrefix: 'jss',
    seed: 'asdf',
  });

export const wrapRootElement = ({ element }) => {
    return (
        <>
          <StyledEngineProvider injectFirst generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {element}
            </ThemeProvider>
          </StyledEngineProvider>
        </>
    );
};

export function replaceHydrateFunction() {
    return (element, container, callback) => {
      ReactDOM.render(element, container, callback);
    };
  }