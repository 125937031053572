exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-auth-callback-js": () => import("./../../../src/pages/admin/auth-callback.js" /* webpackChunkName: "component---src-pages-admin-auth-callback-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admin-logout-js": () => import("./../../../src/pages/admin/logout.js" /* webpackChunkName: "component---src-pages-admin-logout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-order-[id]-js": () => import("./../../../src/pages/order/[id].js" /* webpackChunkName: "component---src-pages-order-[id]-js" */),
  "component---src-pages-order-index-js": () => import("./../../../src/pages/order/index.js" /* webpackChunkName: "component---src-pages-order-index-js" */)
}

